<template lang="pug">
component(:is='component', :to='to', :href='href', :class='buttonClass')
	UiIcon(
		v-if='iconLeft',
		:name='iconLeft',
		:size='computedIconLeftSize',
		:class='`${baseClass}--left-icon`'
	)
	span(:class='{ Wrapped: wrapped }')
		slot
	UiIcon(
		v-if='iconRight',
		:name='iconRight',
		:size='computedIconRightSize',
		:class='`${baseClass}--right-icon`'
	)
</template>

<script lang="ts" setup>
import type { iconNames } from '~/models/icons';

const {
	to,
	href,
	baseClass = 'UiButton',
	variant = 'primary',
	theme = 'filled',
	size = 'md',
	rounded = false,
	square = false,
	iconLeft,
	iconLeftSize = undefined,
	iconRight,
	iconRightSize = undefined,
	wrapped = false,
} = defineProps<{
	to?: string;
	href?: string;
	baseClass?: string;
	variant?: 'white' | 'primary' | 'secondary' | 'tertiary' | 'whatsapp' | 'telegram';
	theme?: 'filled' | 'outline' | 'ghost';
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	rounded?: boolean;
	square?: boolean;
	iconLeft?: (typeof iconNames)[keyof typeof iconNames];
	iconLeftSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	iconRight?: (typeof iconNames)[keyof typeof iconNames];
	iconRightSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	wrapped?: boolean;
}>();

const isHref = computed(() => href || to);
const component = computed(() => (isHref.value ? defineNuxtLink({}) : 'button'));

const computedIconLeftSize = computed(() => iconLeftSize || size);
const computedIconRightSize = computed(() => iconRightSize || size);

const buttonClass = computed(() => {
	const classes = [
		baseClass,
		`${baseClass}--${variant} ${baseClass}--${variant}--${theme}`,
		...(size ? [`${baseClass}--${size}`] : []),
		...(rounded ? [`${baseClass}--rounded`] : []),
		...(square ? [`${baseClass}--square`] : []),
		...(iconLeft ? [`has-left-icon`] : []),
		...(iconRight ? [`has-right-icon`] : []),
	];

	return classes.join(' ');
});
</script>

<style lang="less" scoped>
.UiButton {
	border: 0.0625rem solid transparent;
	.cursor(pointer);
	.box(flex; auto; auto; none; center; center; center; nowrap row);
	.text(@ColorBase; 1rem 1rem @medium; none; none none);
	.transition(all; 250ms; 'sine');
	.appearance(none);

	& > span:not(.Wrapped) {
		.text-nowrap();
	}

	&--rounded {
		.border-radius(@BorderRadiusFull);
	}

	&--left-icon {
		margin-right: 0.5rem;
	}
	&--right-icon {
		margin-left: 0.5rem;
	}

	&--white {
		&--filled {
			border: 0.0625rem solid @ColorWhite;
			.background(@ColorWhite);
			.text(@ColorBase);

			&:hover,
			&[data-popper-shown] {
				.box-shadow(0 0.375rem 0.75rem -0.25rem fade(@ColorBase, 20%));
			}
		}
		&--outline {
			border: 0.0625rem solid @ColorWhite;
			.text(@ColorWhite);
			&:hover,
			&[data-popper-shown] {
				.background(@ColorWhite);
				.text(@ColorBase);
			}
		}
		&--ghost {
			.text(@ColorWhite);
			&:hover,
			&[data-popper-shown] {
				.background(fade(@ColorWhite, 10%));
				.text(@ColorBase);
			}
		}
	}

	&--primary {
		&--filled {
			border: 0.0625rem solid @ColorPrimary;
			.background(@ColorPrimary);
			.text(@ColorWhite);
			&:hover,
			&[data-popper-shown] {
				.box-shadow(0 0.375rem 0.75rem -0.25rem fade(@ColorPrimary, 40%));
			}
		}
		&--outline {
			border: 0.0625rem solid @ColorPrimary;
			.text(@ColorPrimary);
			&:hover,
			&[data-popper-shown] {
				.background(@ColorPrimary);
				.text(@ColorWhite);
			}
		}
		&--ghost {
			.text(@ColorPrimary);
			&:hover,
			&[data-popper-shown] {
				.background(fade(@ColorPrimary, 10%));
				.text(@ColorPrimary);
			}
		}
	}

	&--secondary {
		&--filled {
			border: 0.0625rem solid @ColorWhite;
			.background(@ColorWhite);
			.text(@ColorBase);
			&:hover,
			&[data-popper-shown] {
				.box-shadow(0 0.375rem 0.75rem -0.25rem fade(@ColorBase, 20%));
			}
		}
		&--outline {
			border: 0.0625rem solid @ColorBase;
			.text(@ColorBase);
			&:hover,
			&[data-popper-shown] {
				.background(@ColorBase);
				.text(@ColorWhite);
			}
		}
		&--ghost {
			.text(@ColorBase);
			&:hover,
			&[data-popper-shown] {
				.background(fade(@ColorBase, 10%));
				.text(@ColorBase);
			}
		}
	}

	&--tertiary {
		&--filled {
			border: 0.0625rem solid @ColorBase;
			.background(@ColorBase);
			.text(@ColorWhite);
			&:hover,
			&[data-popper-shown] {
				.box-shadow(0 0.375rem 0.75rem -0.25rem fade(@ColorBase, 20%));
			}
		}
		&--outline {
			border: 0.0625rem solid @ColorWhite;
			.text(@ColorWhite);
			&:hover,
			&[data-popper-shown] {
				.background(@ColorWhite);
				.text(@ColorBase);
			}
		}
		&--ghost {
			.text(@ColorWhite);
			&:hover,
			&[data-popper-shown] {
				.background(fade(@ColorWhite, 10%));
				.text(@ColorWhite);
			}
		}
	}

	&--whatsapp {
		&--filled {
			border: 0.0625rem solid @ColorWhatsapp;
			.background(@ColorWhatsapp);
			.text(@ColorWhite);
			&:hover,
			&[data-popper-shown] {
				.box-shadow(0 0.375rem 0.75rem -0.25rem fade(@ColorWhatsapp, 60%));
			}
		}
		&--outline {
			border: 0.0625rem solid @ColorWhatsapp;
			.text(@ColorWhatsapp);
			&:hover,
			&[data-popper-shown] {
				.background(@ColorWhatsapp);
				.text(@ColorWhite);
			}
		}
		&--ghost {
			.text(@ColorWhatsapp);
			&:hover,
			&[data-popper-shown] {
				.background(fade(@ColorWhatsapp, 25%));
				.text(@ColorWhatsapp);
			}
		}
	}

	&--telegram {
		&--filled {
			border: 0.0625rem solid @ColorTelegram;
			.background(@ColorTelegram);
			.text(@ColorWhite);
			&:hover,
			&[data-popper-shown] {
				.box-shadow(0 0.375rem 0.75rem -0.25rem fade(@ColorTelegram, 60%));
			}
		}
		&--outline {
			border: 0.0625rem solid @ColorTelegram;
			.text(@ColorTelegram);
			&:hover,
			&[data-popper-shown] {
				.background(@ColorTelegram);
				.text(@ColorWhite);
			}
		}
		&--ghost {
			.text(@ColorTelegram);
			&:hover,
			&[data-popper-shown] {
				.background(fade(@ColorTelegram, 25%));
				.text(@ColorTelegram);
			}
		}
	}

	&--xs {
		line-height: 0.75rem;
		font-size: 0.75rem;
		padding: 0.375rem 0.75rem 0.375rem 0.75rem;
		& > .UiButton--left-icon {
			margin-right: 0.25rem;
		}
		& > .UiButton--right-icon {
			margin-left: 0.25rem;
		}
	}
	&--sm {
		line-height: 0.875rem;
		font-size: 0.875rem;
		padding: 0.5rem 0.875rem 0.5rem 0.875rem;
		& > .UiButton--left-icon {
			margin-right: 0.375rem;
		}
		& > .UiButton--right-icon {
			margin-left: 0.375rem;
		}
	}
	&--md {
		line-height: 1rem;
		font-size: 1rem;
		padding: 0.625rem 1rem 0.625rem 1rem;
	}
	&--lg {
		line-height: 1.125rem;
		font-size: 1.125rem;
		font-weight: @regular;
		padding: 0.75rem 1.25rem 0.75rem 1.25rem;
		& > .UiButton--left-icon {
			margin-bottom: 0.125rem;
			margin-right: 0.625rem;
		}
		& > .UiButton--right-icon {
			margin-bottom: 0.125rem;
			margin-left: 0.625rem;
		}
	}
	&--xl {
		line-height: 1.25rem;
		font-size: 1.125rem;
		font-weight: @regular;
		padding: 0.875rem 1.5rem 0.875rem 1.5rem;
		& > .UiButton--left-icon {
			margin-bottom: 0.25rem;
			margin-right: 0.75rem;
		}
		& > .UiButton--right-icon {
			margin-bottom: 0.25rem;
			margin-left: 0.75rem;
		}
	}

	&--square {
		line-height: 1em !important;
		& .UiButton--left-icon,
		& .UiButton--right-icon {
			margin: 0;
		}
		&.UiButton--xs {
			padding: 0.375rem;
		}
		&.UiButton--sm {
			padding: 0.5rem;
		}
		&.UiButton--md {
			padding: 0.625rem;
		}
		&.UiButton--lg {
			padding: 0.75rem;
		}
		&.UiButton--xl {
			padding: 0.875rem;
		}
	}
}
</style>
