<template lang="pug">
svg(v-if='name', :class='svgClass')
	use(:xlink:href='iconPath')
</template>

<script lang="ts" setup>
import type { iconNames } from '~/models/icons';

const {
	name,
	baseClass = 'UiIcon',
	iconClass = '',
	size = 'sm',
} = defineProps<{
	name: (typeof iconNames)[keyof typeof iconNames] | null;
	baseClass?: string;
	iconClass?: string;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'custom';
}>();

const svgClass = computed(() => `${baseClass} ${baseClass}--${size} ${iconClass}`);

const {
	public: { svgSprite },
} = useRuntimeConfig();

const iconPath = computed(() =>
	svgSprite.outputDir && svgSprite.spriteName
		? `/${svgSprite.outputDir}/${svgSprite.spriteName}.svg#${name}`
		: `#${name}`
);
</script>

<style lang="less" scoped>
.UiIcon {
	.box(flex; 1rem; 1rem; none; center; center; center);

	&--xs {
		.box(0.75rem; 0.75rem);
	}
	&--sm {
		.box(1rem; 1rem);
	}
	&--md {
		.box(1.25rem; 1.25rem);
	}
	&--lg {
		.box(1.5rem; 1.5rem);
	}
	&--xl {
		.box(1.75rem; 1.75rem);
	}
}
</style>
